#select {
    font-family: var(--font-family-main, Inter);

    &.sm {
        font-size: 14px;
        width: 200px;
    }
    &.md {
        font-size: 16px;
        width: 300px;
    }
    &.lg {
        font-size: 18px;
        width: 400px;
    }

    .select-control {
        background-color: var(--dark-background-secondary);
        border: 1px solid white;
        border-radius: 50px;
        box-shadow: none;
        cursor: pointer;
        gap: 8px;
        box-sizing: border-box;

        &.sm {
            padding: 8px 14px 8px 20px;
            height: 36px;
        }
        &.md {
            padding: 12px 20px 12px 24px;
            height: 50px;
            &:hover {
                padding-top: 11px;
            }
        }
        &.lg {
            padding: 20px 24px 20px 32px;
        }

        &:hover {
            border: 2px solid white;
            background-color: var(--neutral-dark-dn-40);
        }
    }

    .select-container {
        padding: 0;
    }

    .select-menu {
        background-color: var(--dark-background-secondary);
        border: 1px solid white;
        border-radius: 16px;
        z-index: 10;
        padding: 8px 0;
    }

    .slect-menu-list {
        padding: 0;
    }

    .select-option {
        padding-left: 20px;
        background-color: var(--dark-background-secondary);
        cursor: pointer;

        &:hover {
            background-color: var(--neutral-dark-dn-40);
        }
    }

    .select-value-container {
        padding: 0;
    }

    .select-single-value {
        color: white;
        line-height: 24px;

        &.sm {
            line-height: 20px;
        }
    }

    .select-input {
        padding: 0;
        margin: 0;
    }

    .select-indicators-container > * {
        padding: 0;
    }
}
