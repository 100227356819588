#login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../assets/KONE_People_flow-min.png');

    opacity: 1;
    width: 100%;
    position: relative;
    background-size: cover;

    #intro {
        width: 100%;
        padding: 3rem;
        position: relative;

        #contentWrapper {
            width: 100%;
            margin-top: 10rem;
            padding: 1rem;
            position: relative;
            h1 {
                color: #fff;
                text-align: center;
                /* Display - 64 */
                font-size: 6rem;
                font-family: KONE Information;
                font-style: normal;
                font-weight: 400;
                line-height: 5rem;
                justify-content: center;
            }

            p {
                margin-top: 2rem;
                color: var(--dark-grayscale-100-text, #fff);
                text-align: center;
                text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                /* Heading/H2 */
                font-size: 3.25rem;
                font-family: KONE Information;
                font-style: normal;
                font-weight: 400;
                line-height: 4rem;
            }
        }
        svg {
            width: 7.0625rem;
            height: 2.75rem;
            flex-shrink: 0;
            position: relative;
            filter: brightness(1);
        }
    }

    #loginWrapper {
        display: flex;
        align-items: center;

        #loginForm {
            width: 20%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            input {
                border: 0;
                outline: 1;
                border-bottom: 1px solid rgb(163, 163, 163);
                font-size: 2rem;
                padding: 2rem;
                margin-bottom: 4rem;
                color: #5c5c5c;
                border-radius: 12px;
                border: 2px solid var(--light-grayscale-600-bg-tertiary, #f1f1f1);
                background: var(--light-color-background-bg-blue, #fff);

                &.error {
                    border: 2px solid var(--error);
                }

                &::placeholder {
                    color: #5c5c5c;
                    opacity: 1;
                }

                &:focus {
                    border-bottom: 2px solid white;
                }
            }

            #actions {
                display: flex;
                flex-direction: row;
                font-size: 2rem;
                justify-content: space-between;
                align-items: center;

                .forgot-password-link {
                    color: #fff;
                }

                button {
                    position: relative;
                    background: var(--dark-blue-base, #1450f5);
                    padding: 1.5rem 5rem;
                    border-radius: 1.2rem;
                    cursor: pointer;

                    &:active {
                        background: var(--dark-blue-base, #1450f5);
                    }

                    span {
                        font-weight: 600;
                        font-size: 1.8rem;
                        color: #fff;
                        transition: all 0.2s;
                    }

                    &.pending {
                        span {
                            visibility: hidden;
                            opacity: 0;
                            width: 5rem;
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            width: 1.6rem;
                            height: 1.6rem;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            border: 4px solid transparent;
                            border-top-color: #ffffff;
                            border-radius: 50%;
                            animation: button-loading-spinner 1s ease infinite;
                        }
                    }
                }
            }

            #error-text {
                margin-bottom: 1rem;
                color: var(--error);
            }
        }
    }
}
@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}
