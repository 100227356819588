.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 100%;

    .error-page__title {
        font-size: 12rem;
        font-weight: 700;
    }

    .error-page__message {
        font-size: 2rem;
        font-weight: 500;
    }

    .logout-button {
        margin-top: 2rem;
    }
}