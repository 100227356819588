/*========================================================================
*      SCSS Styles for StationTwin                                       
=========================================================================*/

#dashboard-view {
    width: 100%;
    margin: 7rem 2rem 4rem 4rem;
    height: fit-content;
    font-family: var(--font-family-main, Inter);

    .title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 64px;
        }
    }

    .dashboard-full-length-chart {
        margin: 4rem 0;
    }

    .first-row-info {
        display: flex;
        gap: 4rem;

        font-size: 32px;
        margin-top: 4rem;

        .people-count-wrapper {
            display: flex;
            align-items: center;
            gap: 2rem;
        }
    }

    .dashboard-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5rem;
        margin: 4rem 0;
    }

    .widget-top-row {
        padding-top: 4rem;
        flex: 1;
        width: 20.4rem;
        height: 14rem;
        flex-shrink: 0;
        background: var(--dark-background-primary, #11161a);
        .title {
            /* Heading/H4 */
            justify-content: center;
            display: flex;
            color: var(--dark-grayscale-100, #fff);
            font-family: KONE Information;
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem; /* 150% */
        }
        .values {
            justify-content: center;
            display: flex;
            color: var(--dark-grayscale-100, #fff);
            /* Heading/H4 */
            font-family: KONE Information;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.55rem; /* 150% */
            .small-icon {
                font-size: 0.9em;
            }
        }
    }

    .row {
        justify-content: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 1rem;
        border-collapse: separate;
        border-spacing: 3rem;
        gap: 5rem;
        padding-top: 2rem;
    }
    .row_gap {
        display: flex;
        flex-direction: row;
        border-collapse: separate;
        border-spacing: 3rem;
        gap: 0.5rem;
    }
    .margin_gap_5 {
        margin-left: 5rem;
    }
    .margin_gap_20 {
        margin-left: 35rem;
    }
}
