.analysis-date-selector {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
    #select {
        width: 200px;
        .select-control {
            height: 40px;
            &.md {
                padding: 8px 20px 12px 24px;
            }
        }
    }
}

.cross-flow-chart {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0 100px;
}
