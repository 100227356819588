.gauge-container {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;

    .gauge-charts-title {
        margin-bottom: 1rem;
    }

    .gauge-charts {
        height: 100% !important;
        width: 100%;
    }
}

.chart-container {
    padding: 2rem 4rem;
    flex: 1;
    background: var(--dark-background-primary, #11161a);
    border-radius: 16px;

    font-family: var(--font-family-main, Inter);
    height: 450px;

    .chart-title {
        font-size: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        #select {
            width: 200px;
            .select-control {
               height: 40px;
               &.md {
                padding: 8px 20px 12px 24px;
            }
            }
        }
    }

    .charts-expand {
        height: 90% !important;
    }
}

.spinner-container {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0;
    align-items: center;
}

.error-container {
    height: 100%;
    font-size: 20px;
}
