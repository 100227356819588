@import 'styles/root.css';
@import 'styles/fontFamiles.scss';
@import 'styles/reset.scss';

html {
    font-size: 10px;
}

body {
    margin: 0;
    font-family: 'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: medium;
    color: #fff;
    background: var(--dark-background-secondary);

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-track {
        display: none;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgba(163, 163, 163, 0.4);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(163, 163, 163, 0.7);
        opacity: 1;
    }

    button {
        cursor: pointer;
    }

    input[type='number'] {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        border-radius: 4px;
        text-align: end;
        padding: 2px 10px;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;
        appearance: textfield;
    }
    input[type='text'] {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        border-radius: 4px;
        padding: 0 1rem;
        // text-align: end;
        padding: 2px 10px;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;
        appearance: textfield;
    }

    overflow-y: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.dg.ac {
    z-index: 1000 !important;
}
