.alert-tooltip {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0;

    .tooltip-main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 16px;
        border-radius: 16px;

        .tooltip-body {
            display: flex;
            color: white;
            fill: white;
            gap: 16px;
            align-self: stretch;
            font-size: 20px;

            .tooltip-text-box {
                max-width: 220px;
            }
        }

        .tooltip-button-wrapper {
            display: flex;
            justify-content: flex-end;

            button {
                padding: 4px 10px;
                color: black;
                background-color: white;
            }
        }
    }
}
