button {
    user-select: none;
    cursor: pointer;

    font-family: var(--font-family-main, Inter);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    box-sizing: content-box;

    background-color: var(--kone-blue);
    border-radius: 100px;
    display: flex;
    min-width: 88px;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &.small {
        line-height: 20px;
        padding: 8px 12px;
    }

    &.icon-button {
        min-width: 0;
        padding: 0;
        width: 32px;
        height: 32px;
    }

    &.disabled {
        background-color: var(--blue-300);
        cursor: default;
    }
    &:hover:not(.disabled):not(.secondary) {
        background-color: var(--blue-600);
    }

    &.secondary {
        background-color: var(--surface_blue_secondary);
        color: var(--kone-blue);
        &:hover:not(.disabled) {
            outline: 2px solid var(--kone-blue);
        }
        &.disabled {
            color: var(--blue-300);
            opacity: 0.7;
        }
    }

    .button-spinner {
        border: 6px solid rgba(0, 0, 0, 0.3);
        border-top: 6px solid #007bff;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        height: 100%;
        aspect-ratio: 1 / 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
