.Toastify__toast-container {
    min-width: fit-content;
}

.alert-container.alert-container {
    background-color: transparent;
    padding: 0;
    margin-bottom: 16px;

    .alert-body {
        margin: 0;
        padding: 0;
    }

    .alert-progress-bar.alert-progress-bar {
        border-radius: 4px;
    }
}

.notification-pop-up {
    background-color: #17191c;
    border: 2px solid;
    color: white;
    fill: var(--error);

    display: flex;
    flex-direction: column;
    gap: 1rem;

    max-width: 450px;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;

    .pop-up-top {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .pop-up-main {
            display: flex;
            flex-grow: 1;

            .pop-up-title {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            .pop-up-text-box {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .pop-up-message {
        display: flex;
        align-items: center;
        gap: 8px;
        line-height: 20px;
    }

    .pop-up-bottom {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

        button {
            padding: 12px;
            line-height: 10px;
        }
    }
}
