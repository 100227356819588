.notification-center {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > span {
        font-size: 36px;
        margin-bottom: 2rem;
    }

    .notification {
        padding: 1rem 2rem;
        box-shadow: 0px 0px 4px var(--dark-grayscale-200-inactive, #83868e);
        border-radius: 8px;
        background-color: var(--dark-grayscale-400, #262e33);
        cursor: pointer;

        display: flex;
        justify-content: space-between;

        .notification-second-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .notification-head {
            font-size: 26px;
            margin-bottom: 1rem;
        }

        .notification-body {
            font-size: 20px;
        }

        .notification-footer {
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        .notification-timestamp {
            font-size: 1rem;
            margin-top: 1rem;
        }
    }
}
