.main-view-navbar {
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 2rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    gap: 24px;
}
