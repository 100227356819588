.segment-control {
    display: flex;
    border-radius: 100px;

    align-items: flex-start;
    flex-shrink: 0;

    background: var(--neutral-dark-dn-40, #262b31);

    font-family: var(--font-family-main, Inter);

    padding: 6px;

    &.sm {
        padding: 4px;
    }

    .segment-option {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 100px;

        background-color: transparent;
        color: white;

        font-weight: 600;

        cursor: pointer;

        &.active {
            background: var(--surface_blue_secondary, #f3f6fe);
            color: var(--color-text-default, #141414);
        }

        padding: 6px 14px;
        font-size: 16px;
        width: 160px;

        &.sm {
            padding: 4px 10px;
            font-size: 14px;
            width: 120px;
        }

        &.lg {
            padding: 8px 20px;
            font-size: 18px;
            width: 200px;
        }
    }
}
