/* .parameters {
    margin-top: 70px;
    padding: 0 5rem 5rem 5rem;

    .general-info-container {
        .name {
            font-size: x-large;
            span {
                display: inline-block;
                text-transform: uppercase;
                margin: 0 2rem 2rem 1rem;
            }
        }
    }

    > label {
        display: block;
        width: 100%;
        background-color: var(--dark-grayscale-800-bg-primary);
        padding: 1rem;
        border-radius: 4px;
        font-size: larger;
    }

    .container {
        margin: 2rem 1rem;
    }

    .users {
        .users-details-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            &:first-of-type {
                border-right: 1px solid white;
            }

            .users-details-group {
                .users-title {
                    display: block;
                    text-transform: uppercase;
                    font-size: x-large;
                    margin-bottom: 2rem;
                }

                .users-details-element {
                    margin-bottom: 2rem;

                    > label {
                        display: block;
                        font-size: large;
                        margin-bottom: 1rem;
                    }

                    .users-detail {
                        margin-bottom: 1rem;

                        > label {
                            display: block;
                            &:first-letter {
                                text-transform: uppercase;
                            }
                        }

                        .inline {
                            display: flex;
                            align-items: center;
                            .detail {
                                border: 1px solid white;
                                border-radius: 4px;
                                width: 170px;
                                padding: 6px 8px;
                                display: flex;
                                justify-content: flex-end;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .equipments {
        .equipments-group {
            display: flex;
            margin-bottom: 2rem;

            > label {
                font-size: x-large;
                min-width: 120px;
            }

            .equipment-elements {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }

            .equipment-detail {
                display: flex;
                margin: 0 3rem;
                align-items: center;
                justify-content: space-between;
                width: 170px;

                > label {
                    display: inline-block;
                    margin-right: 2rem;
                    min-width: 50px;
                }
            }
        }
    }

    .grid-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        width: 100%;
    }

    .schedules {
        .schedules-direction-container {
            &:first-of-type {
                border-right: 1px solid white;
            }

            .schedules-direction {
                display: inline-block;
                text-transform: uppercase;
                font-size: x-large;
                margin-bottom: 2rem;
            }

            .schedules-side {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                column-gap: 2rem;
                width: 100%;

                .schedules-element {
                    > label {
                        font-size: larger;
                        text-transform: uppercase;
                    }

                    .schedules-element-data {
                        border-radius: 4px;
                        border: 1px solid white;
                        margin-top: 1rem;
                        padding: 1rem 2rem;
                        display: flex;
                        justify-content: flex-end;
                        min-width: fit-content;
                        width: 70%;
                    }
                }
            }
        }
    }
} */

.mock-selector {
    padding: 1rem 2rem;
    border-radius: 4px;
    border: 1px solid white;

    &.day-of-week::first-letter {
        text-transform: capitalize;
    }
}

.time-displayer {
    padding: 1rem 2rem;
    border-radius: 4px;
    border: 1px solid white;
    width: 50%;
    text-align: end;
}
